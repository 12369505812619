import * as React from "react"
import Layout from '../templates/page-layout';
import OrigStaff from '../assets/images/staff0811.jpg';
import CurrentStaff from '../assets/images/staff2021.jpg';



export default () => {
  return (
      <Layout title="About Us">
        <div className="row">
            <div>
                <div className="col-sm-8">
                    <p>Gary and Linda Kahler started building custom homes in 1979. They moved to Midland in 1981 and Kahler Homes quickly grew. The Kahler’s built their business on the foundation of quality. Gary’s philosophy regarding customer satisfaction is to go the second mile to ensure Kahler Homes has done everything possible to provide quality service along with quality construction. This commitment to quality is why Kahler Homes has thrived and continues its success today.</p>
                </div>
                <div className="col-sm-4">
                    <p>
                        <img className="pull-right img-responsive" style={{border: '1px solid #ccc'}} src={OrigStaff} alt="Photo - Kahler Homes Staff" />
                    </p>
                </div>
            </div>
        </div>
        <blockquote><p>“Dream big, work hard, stay focused, and surround yourself with good people.”</p></blockquote>

        <p>Helen Bowden started with Kahler Homes in 2002. Gary and Linda Kahler taught her the value of doing it right and doing the right thing. Most importantly, they taught her to value the people: both the homeowners and the tradesmen. In 2015, after Linda retired, Helen picked up the reins to continue the traditions learned from the Kahler’s. Helen understands that buying a home is the biggest purchase you will make in your lifetime. It does not have to be stressful! Let the experience of Kahler Homes walk you through the process. Our experienced staff consists of:</p>

        <div className="row">
            <div className="col-sm-5">
                {/* <img className="img-responsive" style={{border: '1px solid #ccc'}} 
                    src={CurrentStaff} alt="Photo - Kahler Homes Staff" /> */}
            </div>
            <div className="col-sm-12">
                <ul>
                    <li><b>Helen Bowden</b>, Owner</li>
                    <li><b>Anthony Martinez</b>, Superintendent</li>
                    <li><b>Laura Dawley</b>, Selections Coordinator</li>
                    <li><b>Courtney Ashley</b>, Sales Consultant</li>
                    <li><b>Courtney Mendoza</b>, Bookkeeper</li>
                    <li><b>Amanda Adamson</b></li>
                    <li><b>Ashleigh Bowden</b>, Draftsman</li>
                    <li><b>Mike Bowden</b></li>
                </ul>
            </div>
        </div>
      </Layout>
    
  )
}

